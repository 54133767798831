<template>
  <div class="card-filter-section">
    <div class="d-flex align-items-center flex-wrap justify-content-between">
      <div class="d-flex align-items-center justify-content-start flex-wrap">
        <p class="filter-title">
          Filters:
        </p>
        <div class="w-lg-100">
          <custom-dropdown
            v-model="user"
            :options="assigneeUserOptions"
            label="Assignee"
            icon="EditIcon"
          />
        </div>

        <div class="">
          <custom-dropdown
            v-model="tags"
            :options="$store.state.app.tagList"
            label="Tags"
            multiple
            icon="EditIcon"
          />
        </div>

        <div class="">
          <custom-dropdown
            v-model="type"
            :options="$store.state.app.projectTypeList"
            label="Ticket Type"
            multiple
            icon="EditIcon"
          />
        </div>
        <div class="">
          <custom-dropdown
            v-model="priority"
            :options="priorityOptions"
            label="priority"
            icon="EditIcon"
          />
        </div>
      </div>
      <div class="d-flex align-items-center">
        <b-input-group
          class="input-group-merge search-project"
          style="min-width: 250px; max-width: 250px; width: 250px"
        >
          <b-form-input
            v-model="debouncedSearch"
            placeholder="Search by Name / Ticket No."
          />
          <b-input-group-append is-text>
            <feather-icon
              v-if="debouncedSearch"
              icon="XIcon"
              @click="debouncedSearch = null,search=null"
            />
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>

        <div class="ml-1">
          <button
            class="apply-filter filterRedesign mr-1"
            :disabled="validateFilter"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="apply-filter-clear filterRedesign"
            @click="clearFilter"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { eventBus } from '@/main'
import debounce from 'lodash/debounce'

export default {
  name: 'FilterSection',
  components: {
    customDropdown,
  },
  props: {
    assignees: {
      // type: Object,
      required: true,
      default: [],
    },
  },
  data() {
    return {
      tags: [],
      type: [],
      priority: null,
      search: null,
      allTags: [],
      typeOptions_array: [],
      user: null,
    }
  },
  computed: {
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
      }, 750),
    },
    assigneeUserOptions() {
      const users = []
      this.assignees.forEach(element => {
        users.push({
          value: element.id,
          label: `${element.full_name}`,
        })
      })
      users.sort((a, b) => a.label.localeCompare(b.label))
      return users
    },
    typeOptions() {
      const type = []
      this.typeOptions_array.forEach(element => {
        type.push({
          value: element.code,
          label: element.name,
        })
      })
      return type
    },
    priorityOptions() {
      return [
        { value: 'high', label: 'High' },
        { value: 'med', label: 'Medium' },
        { value: 'low', label: 'Low' },
      ]
    },
    validateFilter() {
      return false
      // if (
      //   this.type.length ||
      //   this.tags.length ||
      //   this.priority ||
      //   this.user ||
      //   this.search
      // ) {
      //   return false;
      // }
      // return true;
    },
  },
  watch: {
    search(nv) {
      const filter = {
        type: [],
        tags: [],
        priority: null,
        user: [],
        search: nv || null,
      }

      eventBus.$emit('projectTaskFilters', filter)
    },
  },
  mounted() {
    if (this.$store.state.app.projectTypeList.length == 0) {
      this.getProjectType()
    }
  },
  methods: {
    async getProjectType() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'project/type',
        input,
        false,
      )
      if (response && response.data) {
        const typeOptions_array = response.data.project_types
        typeOptions_array.forEach(element => {
          element.value = element.code
          element.label = element.name
        })

        this.$store.commit('app/UPDATE_PROJECT_TYPE_LIST', typeOptions_array)
      }
    },
    applyFilter() {
      const users = []
      this.user == null ? users : users.push(this.user)
      const filter = {
        type: this.type,
        tags: this.tags,
        priority: this.priority,
        user: users,
        search: this.search,
      }
      eventBus.$emit('projectTaskFilters', filter)
    },
    clearFilter() {
      this.type = []
      this.tags = []
      this.priority = null
      this.user = null
      this.search = null
      const filter = {
        type: [],
        tags: [],
        priority: null,
        user: null,
        search: null,
      }
      eventBus.$emit('projectTaskFilters', filter)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/variables/_variables.scss";
@import "../../../assets/scss/component-css/masterSettingFilter.scss"
</style>
