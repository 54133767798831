<template>
  <div class="task-list">
    <div>
      <div class="summary-header d-flex">
        <div
          v-if="projectDtl"
          class="d-flex align-items-center summary-projectnew"
        >
          <b-avatar
            :text="projectDtl.name | avatarText"
            size="40"
            class="summary-projectnew-projectlogo"
            :style="{ 'background-color': '#' + projectDtl.color }"
          />
          <p
            class="summary-projectnew-title"
            :style="{ color: '#' + projectDtl.color }"
          >
            {{ projectDtl.name }}
          </p>
        </div>
        <div class="button-groups d-flex">
          <button
            class="back-button btn btn-secondary mx-2"
            @click="Back(projectDtl.id)"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              width="18"
              height="18"
            />
            <span>Back</span>
          </button>
        </div>
      </div>
    </div>
    <div>
      <filter-section
        :assignees="
          this.projectDtl && this.projectDtl.project_user.length
            ? this.projectDtl.project_user
            : []
        "
      />
    </div>
    <div class="sprint-deatil">
      <div class="d-flex align-items-center">
        <p class="sprint-title">
          <feather-icon
            icon=" FileTextIcon"
            size="15"
          />
          {{ projectDtl && projectDtl.milestone && projectDtl.milestone.title }}
          <span class="devider">|</span>
        </p>
        <p class="sprint-title sprint">
          {{ projectDtl && projectDtl.sprints && projectDtl.sprints.title }}
        </p>
      </div>

      <div
        v-if="projectDtl && projectDtl.sprints.status && projectDtl.sprints.status != 'complete'"
        class="d-flex align-items-center flex-wrap position-relative"
      >
        <b-button
          v-if="addEditRights"
          v-b-modal.addtaskdata1
          variant="primary"
          class="ml-auto add-task-list"
          @click="addTask()"
        >
          + New Task
        </b-button>
      </div>
    </div>
    <!-- Add New task from start -->
    <div class="demo">
      <add-task
        v-if="viewTaskDtl"
        id="addtaskdata1"
        :project-dtl="projectDtl"
      />
    </div>
    <!-- Add New task from start -->
    <task-loader
      v-if="loader"
      :row-num="3"
    />
    <div v-else>
      <new-list-view
        v-for="(status, index) in $store.state.app.projectTaskStatus"
        :id="status.id + '-' + index"
        :key="index"
        :task-list="getTaskList(status.id)"
        :project-dtl="projectDtl"
        :task="status.name"
        :status="status.name"
        :sprint-status="sprintStatus"
      />
    </div>
  </div>
</template>

<script>
import {
  BButton, VBModal,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { eventBus } from '@/main'
import TaskLoader from '@/components/loaders/timesheet-loader.vue'
import AddTask from '@/views/project/List/AddTask.vue'
import FilterSection from '../List/Filter.vue'
import NewListView from './NewListView.vue'

export default {
  name: 'List',
  components: {
    FeatherIcon,
    BButton,
    NewListView,
    FilterSection,
    TaskLoader,
    AddTask,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      completeTasks: 0,
      projectTasks: [],
      applyFilter: false,
      loader: false,
      sprint_id: null,
      projectDtl: null,
      project_id: null,
      milestone_id: null,
      sprintStatus: null,
      viewTaskDtl: null,
      allTasksStatus: [],
    }
  },
  computed: {
    addEditRights() {
      if (this.userInfo && this.userInfo.role === 'SA') {
        return true
      } if (
        this.projectDtl
        && this.projectDtl.project_user
        && this.projectDtl.project_user.length
      ) {
        const user = this.projectDtl.project_user.find(
          pu => pu.id === this.userInfo.id,
        )
        if (
          user
          && (user.access_rights === 'project_owner'
            || user.access_rights === 'co_ordinator')
        ) {
          return true
        }
      }
      return false
    },
  },
  mounted() {
    this.sprint_id = this.$route.params.id
    this.getProjectDetail()
    eventBus.$on('projectTaskFilters', data => {
      if (data) {
        this.applyFilter = true
        this.getProjectTaskLists(data)
      }
    })
    eventBus.$on('reloadCompleteTaskList', data => {
      this.getProjectTaskLists()
    })
  },
  destroyed() {
    eventBus.$off('projectTaskFilters')
    eventBus.$off('reloadCompleteTaskList')
  },
  methods: {
    async getAllTaskStatus() {
      const input = {
        sort_field: 'display_order',
        sort_order: 'asc',
        type: 'project',
        is_active: true,
        project_id: this.projectDtl.id ? this.projectDtl.id : this.$route.params.id,

      }
      const response = await this.getHTTPPostResponse(
        'project/task-status',
        input,
        false,
      )
      if (response && response.data) {
        this.allTasksStatus = response.data.task_statuses
        this.$store.state.app.projectTaskStatus = response.data.task_statuses
      }
    },

    async getProjectDetail() {
      this.loader = true
      const input = {
        sprint_id: this.sprint_id,
      }
      const response = await this.getHTTPPostResponse(
        'project/specific-sprint',
        input,
        false,
      )
      if (response && response.status == 200 && response.data) {
        this.projectDtl = response.data.project
        this.getAllTaskStatus()

        this.sprintStatus = response.data.project.sprints.status
        this.getProjectTaskLists()
        this.getProjectUser(this.projectDtl.id)

        this.projectDtl.project_user = this.$store.state.app.projectUsers
      } else {
        this.loader = false
      }
    },
    async getProjectTaskLists(filters = null) {
      if (this.projectDtl.sprints.id) {
        this.loader = true
        const input = {
          project_id: this.projectDtl.id,
          milestone_id: this.projectDtl.milestone.id,
          sprint_id: this.projectDtl.sprints.id,
          search: filters && filters.search ? filters.search : null,
          tags: filters && filters.tags.length ? filters.tags : [],
          user_id: filters && filters.user ? filters.user : null,
          type: filters && filters.type.length ? filters.type : [],
          priority: filters && filters.priority ? filters.priority : null,
        }
        const response = await this.getHTTPPostResponse(
          'project/task',
          input,
          false,
        )
        if (response && response.data) {
          this.projectTasks = response.data.project_tasks
        }
        this.loader = false
      }
    },
    Back(val) {
      this.$router.push({
        name: 'projectTask',
        params: {
          id: 'master-plan',
          id2: this.projectDtl.name,
          id3: val,
        },

        replace: true,
      })
    },
    getTaskList(status) {
      const tasks = []
      if (this.projectTasks && this.projectTasks.length) {
        this.projectTasks.forEach(element => {
          if (element.status_id === status) {
            tasks.unshift(element)
          }
        })
      }
      return tasks
    },
    addTask() {
      this.viewTaskDtl = true
    },
    async getProjectUser(id) {
      this.loader = true
      const response = await this.getHTTPPostResponse(
        'project/user/sort-project-user-list',
        {
          project_id: id,
        },
        false,
      )
      if (response && response.status === 200) {
        const { data } = response
        this.projectDtl.project_user = data.project_users

        setTimeout(() => {
          this.$store.state.app.projectUsers = data.project_users
          this.loader = false
        }, 300)
      }
      this.loader = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/component-css/list.css";
.back-button {
  background-color: #3479fb !important;
  padding: 5px;
  color: #ffffff;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #3479fb !important;
    color: #ffffff;
  }
}
</style>
